import React from "react";
import { Link } from "gatsby";
import logo from '../images/apple-icon-mls-red.svg';

const Header = () => (
  <header className="text-gray-700 body-font shadow-md">
    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <Link to="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-teal-500 rounded-full" viewBox="0 0 24 24">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg> */}
        <img src={logo} className="w-10 h-10 text-white rounded-full" alt="MLS Learning Logo" />
        <span className="ml-3 text-2xl">MLS Learning, Inc.</span>
      </Link>
      <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <Link to="/about" className="mr-5 hover:text-gray-900">About Us</Link>
        <Link to="/history" className="mr-5 hover:text-gray-900">History</Link>
        <Link to="/philosophy-goals" className="mr-5 hover:text-gray-900">Philosophy &amp; Goals</Link>
        <Link to="/academics" className="mr-5 hover:text-gray-900">Academic Enrichment</Link>
        <Link to="/trips-events" className="mr-5 hover:text-gray-900">Trips &amp; Events</Link>
        <Link to="/donations" className="mr-5 hover:text-gray-900">Donations to MLS</Link>
      </nav>
      {/* <button className="inline-flex items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0">Button
      <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </button> */}
    </div>
  </header>
)

export default Header
