
import React from "react";

function Footer() {
  return (

    <footer className="text-gray-700 body-font">
      <div className="bg-gray-200">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">© 2023 MLS Learning, Inc.</p>
          <p className="inline-flex text-center text-sm sm:text-left text-gray-600 sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">

            All donations made to MLS are <br className="sm:hidden" /> tax-deductible and greatly appreciated.

          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer
